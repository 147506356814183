import randomColor from "randomcolor";

import { zeffyTheme } from "../theme/theme";

const colors = [
  zeffyTheme.palette.primary.main,
  zeffyTheme.palette.secondary.main,
  zeffyTheme.palette.tertiary.main,
  zeffyTheme.palette.error.main,
  zeffyTheme.palette.warning.main,
];

export const getPaletteRandomColor = () => {
  return colors[Math.floor(Math.random() * 10) % 5];
};
export interface RandomColorSetting {
  count: number;
  luminosity: "bright" | "light" | "dark" | "random";
}

export const getRandomColor = (color: string | undefined, randomColorSetting: RandomColorSetting) => {
  try {
    return randomColor({
      count: 10,
      luminosity: randomColorSetting.luminosity,
      hue: color,
    })?.[Math.floor(Math.random() * 100) % randomColorSetting.count];
  } catch {
    return randomColor({
      count: 10,
      luminosity: randomColorSetting.luminosity,
      hue: "monochrome",
    })?.[Math.floor(Math.random() * 100) % randomColorSetting.count];
  }
};

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

// From rgb(231, 229, 246) to [0.9058823529411765,0.8980392156862745,0.9647058823529412,1]
export const rgbStringToRgbaString = (rgbString: string) => {
  const result = rgbString.match(/rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*\)/);
  if (!result) {
    throw new Error("Invalid rgb color format");
  }
  const r = parseInt(result[1], 10) / 255;
  const g = parseInt(result[2], 10) / 255;
  const b = parseInt(result[3], 10) / 255;
  return `[${r},${g},${b},1]`;
};

// From hsl(231, 29%, 46%) to [0.9058823529411765,0.8980392156862745,0.9647058823529412,1]
export const hslStringToRgbaString = (hslString: string) => {
  const result = hslString.match(/hsl\(\s*(\d+),\s*(\d+)%,\s*(\d+)%\s*\)/);
  if (!result) {
    throw new Error("Invalid hsl color format");
  }

  const h = parseInt(result[1], 10) / 360;
  const s = parseInt(result[2], 10) / 100;
  const l = parseInt(result[3], 10) / 100;

  let r: number;
  let g: number;
  let b: number;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) {
        t += 1;
      }
      if (t > 1) {
        t -= 1;
      }
      if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
      }
      if (t < 1 / 2) {
        return q;
      }
      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
      }
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return `[${r},${g},${b},1]`;
};
